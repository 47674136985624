.component-table {
    margin: 0 1%;
    width: 98%;

    max-height: 75vh;

    color: white;
    background: rgba(255, 255, 255, .2);
    border-radius: 5px;
}

.component-table-header {
    display: flex;
    padding: .6vw;
    border-bottom: 1px solid rgba(255, 255, 255, .4);
}

.component-table-header h4 {
    width: 25%;
    text-align: left;
}

.component-table-body {
    overflow-y: auto;
    max-height: 70vh;
}

.component-table-body-item {
    padding: .8vw .6vw;
    border-bottom: 1px solid rgba(255, 255, 255, .4);
}

.component-table-body-item:last-child {
    border-bottom: none;
}

.component-table-body-item-divisions {
    display: flex;
    align-items: center;
}

.component-table-body-item-division {
    width: 25%;
    margin: 0 .5vw;
}

.component-table-body-item-division img {
    float: left;
    width: 48px;
    height: 48px;
    margin-right: .6vw;
    border-radius: 25px;
}

.component-table-body-item-division p {
    font-size: 12.5px;
    color: rgba(255, 255, 255, .75);
}

.component-table-body-item-division h4,
.component-table-body-item-division h5 {
    font-size: 14px;
}

.component-table-body-item-division h5,
.component-table-body-item-info h5 {
    text-align: left;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;

    font-size: 12px;

    background-color: rgba(255, 255, 255, .8);
    color: black;

    margin-bottom: .3vw;
    margin-right: .3vw;
    padding: .2vw .4vw;

    border-radius: 15px;
}

.component-table-body-item-division button {
    background: none;
    font-size: 2rem;
    color: white;

    margin-top: 1%;
    margin-right: 1%;
    cursor: pointer;
}

.component-table-body-item-infos {
    margin: .8vw 0;

    display: flex;
    align-items: center;

    transition: all .5s;
}

.component-table-body-item-info {
    width: 25%;
    margin: 0 .5vw;
}

.component-table-body-item-info h4 {
    font-size: 14px;
}

.component-table-body-item-info p {
    font-size: 12px;
    color: rgba(255, 255, 255, .75);
}

.component-table-active {
    background-color: rgb(23, 153, 23);
    display: flex;
    float: left;
    padding: .25vw .5vw;
    border-radius: 15px;
}

.component-table-inactive {
    background-color: var(--cor-imarketing);
    display: flex;
    float: left;
    padding: .25vw .5vw;
    border-radius: 15px;
}

.component-table-button-exclude {
    color: var(--cor-imarketing) !important;
}

.component-table-button-active {
    color: rgb(23, 153, 23) !important;
}

.component-table-item-button {
    cursor: pointer;
    padding: .35vw .5vw;
    border-radius: 5px;
    background: rgba(255, 255, 255, .15);
    color: white;

    font-size: 14.5px;
}