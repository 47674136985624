/* CURSOR */
.component-klibpox-noticia-cursor {
    cursor: pointer;
}

/* NOTICIA ITEM */
.component-klipbox-noticia-item {
    min-height: 120px;
    margin: .4vw .6vw;

    background: rgba(255, 255, 255, .25);
    border-radius: .5em;
}

.component-klipbox-noticia-item:first-child {
    margin-top: 0;
}

.component-klipbox-noticia-item:last-child {
    margin-bottom: 0;
}

/* HEADER */
.component-klipbox-noticia-item-header,
.component-klipbox-noticia-item-footer {
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, .65)
}

.component-klipbox-noticia-item-header span,
.component-klipbox-noticia-item-footer span {
    font-size: .8rem;
    margin: .25vw .6vw;
}

/* CONTENT */
.component-klipbox-noticia-item-content {
    color: white;
    padding: .15vw .6vw;
    font-size: .85rem;
    height: 64px;
    overflow-y: auto;
}

/* CLASSIFICATION */
.component-klipbox-noticia-item-positiva {
    border: 1px solid #86e29b;
}

.component-klipbox-noticia-item-positiva:hover {
    background-color: #48644e;
    border: 1px solid #28a745;
}

.component-klipbox-noticia-item-negativa {
    border: 1px solid #f3b7bd;
}

.component-klipbox-noticia-item-negativa:hover {
    background-color: #6e585a;
    border: 1px solid #dc3545;
}

.component-klipbox-noticia-item-neutra {
    border: 1px solid #ffe7a0;
}

.component-klipbox-noticia-item-neutra:hover {
    background-color: #645e4a;
    border: 1px solid #ffc107;
}

.component-klipbox-noticia-item-nao-analisado {
    border: 1px solid rgba(255, 255, 255, .65);
}

.component-klipbox-noticia-item-nao-analisado:hover {
    background-color: rgba(255, 255, 255, .35);
    border: 1px solid rgba(255, 255, 255, .35);
}