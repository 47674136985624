/* BAR */
.component-bar {
    width: 28vw;
}

.component-bar-canvas {
    display: block;
    padding: .9vw;
    padding-top: .7vw;
    padding-right: 1.1vw;
}

.component-bar-graph {
    background: rgb(34, 37, 46);
    transition: all .5s;

    height: 68vh;
    border-radius: 10px;
}