.component-table-skeleton-loading::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-image: linear-gradient(to right,
            rgba(255, 255, 255, .35),
            rgba(0, 0, 0, .10) 40%,
            rgba(255, 255, 255, .35));
    background-size: 450px 400px;
    background-repeat: no-repeat;
    animation: shimmer 2s linear infinite;
}

.component-table-skeleton-body {
    background: rgba(255, 255, 255, .25);
    color: transparent;
    border-radius: 10px;

    display: inline-block;
    position: relative;
    overflow: hidden;
}

.component-table-skeleton {
    margin: 0 1%;
    width: 98%;

    color: white;
    background: rgba(255, 255, 255, .2);
    border-radius: 5px;
}

.component-table-header-skeleton {
    display: flex;
    justify-content: space-between;

    padding: .6vw;
    border-bottom: 1px solid rgba(255, 255, 255, .4);
}

.component-table-header-skeleton h4 {
    width: 15%;
    text-align: left;
}

.component-table-body-item-skeleton {
    padding: .8vw .6vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, .4);
}

.component-table-body-item-skeleton:last-child {
    border: none;
}