/* BAR */
.component-bar-skeleton {
    width: 27vw;
    margin-right: 1vw;
}

/* GRAPH */
.component-bar-skeleton-content {
    background: rgb(34, 37, 46);
    transition: all .5s;

    height: 67vh;
    border-radius: 10px;
    padding: 10px 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

/* LINE */
.component-bar-skeleton-graph-line {
    height: 3vh;
    width: 25vw;
}