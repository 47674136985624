.component-info-dropdown {
    min-width: 16vw;
    width: 16vw;
    padding: 1%;

    transform: translateY(.775vw) translateX(-7.85vw);

    background-color: rgb(34, 37, 46);
    border: 1px solid rgba(255, 255, 255, .65);
    border-radius: 5px;

    text-align: center;
}

.component-info-dropdown p {
    color: white;
    padding: .3vw;
}

.component-info-dropdown strong {
    font-weight: bold;
}