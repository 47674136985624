.component-action {
    float: left;
    text-align: center;
    display: flex;
    justify-content: center;

    padding: .25vw .45vw;
}

/* COMPONENT ACTION NAME */
.component-action-name {
    display: none;
    position: absolute;
    transform: translateY(-1.5vw);
    z-index: 1;

    color: white;
    transition: all .5s ease-out;
}

/* COMPONENT ACTION ICON */
.component-action-icon {
    z-index: 2;
    cursor: pointer;

    color: white;

    text-align: center;
    padding-top: .25vw;
    font-size: 1.2vw;

    transition: all .5s;
    border-radius: 50%;
    border: 1px solid white;

    width: 2vw;
}

.component-action-icon:hover {
    background-color: rgba(255, 255, 255, 1);
    color: black;
}

/* SHOW DROPDOWN AND NAME */
.component-action:hover .component-action-name,
.component-action:hover .component-dropdown {
    opacity: 1;
    visibility: initial;
    transition: all .5s;
}