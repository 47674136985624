/* NOTIFICATION */
.component-notification {
    width: 16vw;
    height: 32vh;

    transform: translateY(.75vw) translateX(-7.85vw);

    background-color: white;
    border-radius: 5px;

    overflow-y: scroll;
}

.component-notification::-webkit-scrollbar {
    width: 4px;
}

.component-notification::-webkit-scrollbar-thumb {
    background-color: #3778ff;
    border-radius: 8px;
}

/* NOTIFICATION HEADER */
.component-notification-header {
    color: black;
    padding: 2% 0;
    border-bottom: 1.5px solid black;
}