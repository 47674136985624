/* ITEM */
.component-notification-skeleton-content-item {
    font-size: .7vw;
    line-height: 1vw;
    padding: 3% 4%;
    text-align: left;

    border-bottom: 1px solid black;
}

.component-notification-skeleton-loading::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    background-image: linear-gradient(to right, #d9d9d9 20%, rgba(0, 0, 0, .10) 40%, #d9d9d9 60%, #d9d9d9 100%);
    background-size: 450px 400px;
    background-repeat: no-repeat;
    animation: shimmer 1.5s linear infinite;
}

/* CATEGORY */
.component-notification-skeleton-category {
    background: #d9d9d9;
    color: transparent;
    border-radius: 20px;
    margin-right: 8px;

    display: inline-block;
    position: relative;
    overflow: hidden;
}

/* TITLE */
.component-notification-skeleton-title {
    font-weight: 600;
    background: #d9d9d9;
    color: transparent;
    border-radius: 15px;

    display: inline-block;
    position: relative;
    overflow: hidden;
}

/* DESCRIPTION */
.component-notification-skeleton-description {
    background: #d9d9d9;
    margin-top: .2vw;
    width: 100%;
    height: 20px;
    border-radius: 20px;

    display: inline-block;
    position: relative;
    overflow: hidden;
}

/* KEY FRAMES */
@keyframes shimmer {
    0% {
        background-position: -450px 0;
    }

    100% {
        background-position: 450px 0;
    }
}