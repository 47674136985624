.component-klipbox-noticia {
    margin: 0 1vw;
    color: white;

    min-width: 58vw;
    width: 58vw;
}

/* HEADER */
.component-klipbox-noticia-header {
    margin-bottom: .8%;
    display: flex;
    justify-content: space-between;

    color: rgba(255, 255, 255, .65);
    border-bottom: 1px solid white;
}

/* TITLE */
.component-klipbox-noticia-title {
    max-height: 65px;
    overflow-y: scroll;
}

.component-klipbox-noticia-title h1 {
    font-size: 24px;
    padding-bottom: 7.5px;
}

/* FILTROS LIST */
.component-klipbox-noticia-filtros-list {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
}

/* FILTROS */
.component-klipbox-noticia-filtros {
    margin-bottom: 1%;
    width: 25.1%
}

.component-klipbox-noticia-filtros select {
    font-size: 15.5px;
}

.component-klipbox-noticia-filtros select,
.component-klipbox-noticia-filtros input {
    width: 14.2vw;
    font-size: 14.8px;
}

.component-klipbox-noticia-filtros p {
    font-size: 16px;
    margin-bottom: .5%;
}

.component-klipbox-noticia-link input {
    width: 57.9vw;
    height: 40px;
    font-size: 15px;
}

/* FILTRO ITEM */
.component-klipbox-noticia-filtro-item {
    width: 25%;
}

/* CONTENT */
.component-klipbox-noticia-content {
    font-size: 15px;
}

.component-klipbox-noticia-content h1 {
    font-size: 22px;
}

.component-klipbox-noticia-contents {
    background-color: rgba(255, 255, 255, .25);
    border-radius: 5px;

    padding: .3vw;
    overflow-y: auto;
    height: 37vh;
    margin-bottom: 1%;
}

.component-klipbox-noticia-contents mark {
    background-color: var(--cor-imarketing);
    color: white;
    border-radius: 2.5px;
}

/* BUTTON ACTIONS */
.component-klipbox-noticia-button-actions {
    display: flex;
    justify-content: flex-end;
}

.component-klipbox-noticia-button-action {
    margin: 0 5px;
    padding: 5px 7.5px;
    border-radius: 5px;
    width: auto;
    font-size: .9rem;
}