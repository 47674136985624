.component-klipbox-news {
    display: flex;
    transition: all .5s;
}

.klibpox-noticia-cursor {
    cursor: pointer;
}

.klipbox-noticias {
    min-width: 22.5vw;
    width: 22.5vw;
    height: 71vh;

    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.klipbox-noticias::-webkit-scrollbar {
    width: 8px;
}

.klipbox-noticias::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, .25);
}

.klipbox-noticias::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, .25);
    border-radius: 10px;
}