/* LINE */
.component-line {
    width: 24vw;
}

.component-line-canvas {
    display: block;
    padding: .9vw;
    padding-top: .7vw;
    padding-right: 1.1vw;
}

.component-line-graph {
    background: rgb(34, 37, 46);
    transition: all .5s;

    height: 30vh;
    border-radius: 10px;
}