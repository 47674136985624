/* INTERACTION */
.component-interaction {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* CONTENT */
.component-interaction-content {
    width: 28vw;
    margin-top: -4vw;
    color: rgba(255, 255, 255, .3);
}

/* ICON */
.component-interaction-icon {
    padding: 2vw;
    padding-bottom: .5vw;
    font-size: 11rem;

    display: flex;
    justify-content: center;
}

/* TEXT */
.component-interaction-text h1 {
    font-size: 1.6rem;
    text-align: center;
}