/* PIE */
.component-pie-skeleton {
    width: 24vw;
    min-width: 24vw;

    margin-right: 1vw;
}

/* GRAPH */
.component-pie-skeleton-graph {
    background: rgb(34, 37, 46);
    transition: all .5s;

    height: 29vh;
    border-radius: 10px;
}

/* GRAPH CIRCLE */
.component-pie-skeleton-graph-circle {
    width: 9vw;
    height: 18vh;
    border-radius: 100% !important;

    margin-top: 1.4vw;
}

/* LEGENDS */
.component-pie-skeleton-graph-legends {
    display: flex;
    justify-content: space-evenly;

    margin-top: 1.9vw;
    font-size: .9rem;
}