.component-info {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    transition: all .5s;
}

.component-info svg {
    color: white;
    font-size: 1.8rem;
}

.component-info:hover .component-dropdown {
    opacity: 1;
    visibility: initial;
    transition: all .5s;
}

.component-info .component-dropdown-build {
    transform: translateY(1.2vw);
    border-bottom: 15px solid rgba(255, 255, 255, .70);
}