/* PIE */
.component-pie {
    width: 24vw;
}

.component-pie-canvas {
    padding: .5vw;
}

.component-pie-graph {
    background: rgb(34, 37, 46);
    transition: all .5s;

    height: 30vh;
    border-radius: 10px;
}