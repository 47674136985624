.login-align-itens {
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.login-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    padding: 25px;

    background-image: url("../../files//images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.login-box {
    width: 475px;
    height: 445px;
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 30px;
}

/* BOX HEADER */
.login-box-header img {
    display: block;
    margin: 2vh auto 0 auto;
}

/* BOX CONTENT */
.login-box-content {
    border-color: red;
}

/* FOOTER */
.login-box-footer {
    text-align: center;
    margin: 1.3vw 0;
    font-size: .75rem;
}