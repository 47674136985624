.component-klipbox-relatorio-filtros {
    margin: 0 2.7vw;
    padding: .6vw .8vw;

    transition: all .5s;
    background: rgb(34, 37, 46);
    border-radius: 10px;

    display: inline-flex;
    align-items: center;

    width: 82.2vw;
}

/* BUTTON */
.component-klipbox-relatorio-button {
    background-color: var(--cor-imarketing);
    border-radius: 5px;
    margin: 0;
    font-size: .9rem;
    padding: .24vw .5vw;
    transition: all .7s;
    width: 6.5vw;
}

.component-klipbox-relatorio-button-reset {
    margin-left: .7vw;
    background-color: rgba(255, 255, 255, .25);
}

.component-klipbox-relatorio-pdf {
    font-size: 26px;
    margin-right: .6vw;
    background-color: transparent;
    cursor: pointer;

    display: inline-flex;
    margin-left: auto;
}

.component-klipbox-relatorio-pdf svg path {
    stroke: white;
}