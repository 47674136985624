/* ITEM */
.component-notification-content-item {
    font-size: .7vw;
    line-height: 1vw;
    padding: 3% 4%;
    text-align: left;

    border-bottom: 1px solid black;
}

/* CATEGORY */
.component-notification-category {
    background-color: #3778ff;
    color: #fff;
    display: inline-block;
    padding: 0 8px;
    font-weight: 400;
    border-radius: 20px;
    margin-right: 3px;
}

/* TITLE */
.component-notification-title {
    font-weight: 600;
}

/* DESCRIPTION */
.component-notification-description {
    margin-left: .25vw;
}

/* CATEGORY TYPES */
.category-fix {
    background-color: var(--cor-imarketing);
}

.category-implementation {
    background-color: #529D18;
}

.category-notification {
    background-color: #DAA520;
}