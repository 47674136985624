/* INPUT COMPONENTS */
.input-components:nth-child(1) {
    margin-top: 43px;
}

.input-components {
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    margin-top: 15px;

    height: 45px;
    width: 100%;
}

.input-components input {
    width: 90%;
    height: 100%;
    border-radius: 0 10px 10px 0;
    padding-left: 10px;
    font-size: 13px;
}

.input-components span {
    float: left;
    height: 45px;
    width: 10%;
    border-right: 1px solid #d8d8d8;
}

.input-components svg {
    font-size: 20px;
    display: block;
    margin: 12px auto auto;
}

/* COMPONENT INPUT */
.component-input {
    height: 45px;
    width: 100%;
    padding-left: 15px;

    border: 1px solid #d8d8d8;
    border-radius: 10px;
}

.component-input::placeholder {
    color: rgb(32, 32, 32);
}

/* COMPONENT INPUT LABEL */
.input-components-label {
    width: 100%;
    float: left;
}

.component-input-label {
    font-size: 13px;
    margin-left: 6px;
}