/* MENU ITEM */
.component-menu-item {
    padding: 0 .4vw;
    height: 2.4vw;
    margin-bottom: 12px;

    border-radius: 5px;
    color: white;
    cursor: pointer;

    transition: all .8s;

    width: 100%;
    display: inline-flex;
    align-items: center;
}

.component-show-transparent {
    background-color: rgba(255, 255, 255, .1);
}

.component-menu-item:hover {
    background-color: rgba(255, 255, 255, .1);
}

.component-menu-item span {
    font-size: 20px;
    margin: 0 .4vw;
}

.component-menu-item p {
    text-align: center;
    font-size: .9rem;
    margin: 0 auto;
}

/* Menu Item Show */
.component-menu-item-show {
    float: right;

    background-color: #e21013;
    height: 32px;
    width: .4vw;

    border-radius: 5px;
    margin-right: -.4vw;
}