/* LINE */
.component-line-skeleton {
    width: 24vw;
    min-width: 24vw;

    margin-top: .9vw;
    margin-right: 1vw;
}

/* GRAPH */
.component-line-skeleton-graph {
    background: rgb(34, 37, 46);
    transition: all .5s;

    height: 29vh;
    border-radius: 10px;
}

/* LEGENDS */
.component-line-skeleton-graph-legends {
    display: flex;
    justify-content: space-evenly;

    padding-top: .8vw;
    font-size: .9rem;
}

/* LINE */
.component-line-skeleton-graph-line {
    height: 2vh;
    width: 22vw;
    margin: .2vw 0;
}