@font-face {
    font-family: "Poppins";
    src: url("./files/fonts/poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: 300;
}

html {
    position: relative;
    height: 100%;
    background-color: rgb(46, 49, 58) !important;
}

* {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;

    --cor-imarketing: #e21013;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, .25);
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(255, 255, 255, .25);
    border-radius: 10px;
}

/* COLORS */
.component-color-gold {
    background-color: #F2AE30;
}

.component-color-red {
    background-color: var(--cor-imarketing);
}

/* DASHBOARD */
.dashboard {
    width: 100%;
}

/* DASHBOARD-CONTENT */
.dashboard-content {
    width: 88vw;
    min-width: 88vw;

    position: absolute;
    right: 0;
}

/* DASHBOARD HEADER */
.dashboard-header {
    padding: 1.2vw 1.7vw;
}

/* DASHBOARD SUB MENU */
.dashboard-sub-menu {
    width: 70vw;

    display: inline-flex;
    justify-content: space-between;
}

/* DASHBOARD SUB MENU ACTIONS */
.dashboard-sub-menu-actions {
    float: right;
    margin: .5vw 1.5vw;
    display: inline-flex;
    justify-content: space-between;
}

/* Dashboard User Menu */
.dashboard-user-menu {
    float: right;
    width: 14.45vw;
    height: 100%;
}

/* DASHBOARD ITENS */
.dashboard-itens {
    background: rgb(34, 37, 46);

    margin: 0 2.7vw;
    padding-bottom: 2vh;
    border-radius: 10px;
}

/* KLIPBOX RESULTS */
.klipbox-results {
    padding: .6vw .9vw;
    padding-bottom: .2vw;
    font-size: .9rem;
    color: rgba(255, 255, 255, .75);
}

.klipbox-results-newNews {
    padding-left: .5vw;
    color: rgba(255, 255, 255, .50);
}

.klipbox-results-newNews strong {
    color: #86e29b;
}

/* KLIPBOX GRAPH */
.klipbox-relatorio-graphs {
    height: 75vh;
    margin: 1.25vw 2.7vw;

    display: flex;
    flex-wrap: wrap;

    overflow-y: auto;
}

/* KLIPBOX GRAPH TITLE */
.klipbox-graph-title {
    font-size: .6rem;
    color: white;
}

/* KLIPBOX GRAPH LEGEND */
.klipbox-graph-legend {
    font-size: .85rem;
    margin-bottom: .4vw;

    color: rgba(255, 255, 255, .5);
}

/* KLIPBOX SKELETON BODY */
.klipbox-skeleton-body {
    background: rgba(255, 255, 255, .25);
    color: transparent;
    border-radius: 10px;

    display: inline-block;
    position: relative;
    overflow: hidden;
}

/* KLIPBOX SKELETON LOADING */
.klipbox-skeleton-loading::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-image: linear-gradient(to right,
            rgba(255, 255, 255, .35),
            rgba(0, 0, 0, .10) 40%,
            rgba(255, 255, 255, .35));
    background-repeat: no-repeat;
    animation: shimmer 2s linear infinite;
}

/* GRAPH */
.component-klipbox-relatorio-graphs-top10 {
    margin-top: 2vh;
    margin-right: 2vh;
    margin-bottom: 1vh;
}

.component-klipbox-relatorio-graph-top10-content {
    background: rgb(34, 37, 46);
    padding: .7vw .5vw;
    margin-top: .5vw;
    border-radius: 10px;
    width: 24vw;
    height: 67.9vh;
    overflow-y: auto;
}

.component-klipbox-relatorio-graph-top10-item {
    background: rgba(255, 255, 255, .3);
    padding: .3vw;
    border-radius: 5px;
    margin: 2vh .5vh;
}

.component-klipbox-relatorio-graph-top10-item:first-child {
    margin-top: .5vh;
}

.component-klipbox-relatorio-graph-top10-item:last-child {
    margin-bottom: .5vh;
}

.component-klipbox-relatorio-graph-top10-item-header {
    color: rgba(255, 255, 255, .6);
    font-size: 12.5px;
    text-align: right;
}

.component-klipbox-relatorio-graph-top10-item h1 {
    color: white;
    font-size: .9rem;
    margin-top: .5vh;
    margin-bottom: 1.5vh;
}

.component-klipbox-relatorio-graph-top10-item p span {
    color: white;
}

.component-klipbox-relatorio-graph-top10-item-footer {
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, .6);
    font-size: .85rem;
}

.component-klipbox-relatorio-graph-top10-item-footer a {
    color: rgba(255, 255, 255, .6);
}