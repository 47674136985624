/* FILTRO STYLE */
.component-filtro-style {
    color: white;
    background: rgba(255, 255, 255, .25);
    border-radius: .3rem;
    margin-right: .7vw;

    transition: all 1s;
}

.component-filtro-style:hover {
    box-shadow: 0 0 5px rgba(255, 255, 255, .5);
    border-radius: .3rem;
}

/* FILTRO SELECT */
.component-filtro-select {
    padding: .2vw 1vw;
    padding-left: .2vw;
}

.component-filtro-select option {
    color: black;
    background: white;
}

/* FILTRO INPUT */
.component-filtro-input {
    padding: .25vw .4vw;
}

.component-filtro-input::placeholder {
    color: white;
}

/* FILTRO ITEM */
.component-filtro-item {
    display: inline-flex;
    align-items: center;
}

/* FILTRO ITEM ICON */
.component-filtro-item-icon {
    color: white;
    margin-top: .45vw;
}

.component-filtro-item-icon span {
    font-size: 1.4vw;
}

.component-filtro-icon-left {
    margin-right: .4vw;
    margin-left: -.2vw;
}

.component-filtro-icon-right {
    margin-left: -.3vw;
}

/* FILTRO DATE */
.component-filtro-date {
    padding: .08vw .2vw;
    font-size: .88rem;
    cursor: pointer;
}

.component-filtro-date {
    cursor: pointer;
}

.react-calendar__tile--active {
    background: var(--cor-imarketing);
}

.react-daterange-picker__wrapper {
    border: none;
}

.react-calendar__month-view__weekdays__weekday {
    color: black;
}

.react-daterange-picker__inputGroup__input,
.react-date-picker__inputGroup__divider,
.react-daterange-picker__button__icon {
    color: white;
    stroke: white;
}