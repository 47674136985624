.top10 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.top10-logo-header {
    margin: 5% 0;
    margin-bottom: 4%;
    width: 32.5vw;
}

.top10-logo-header img {
    width: 100%;
}

.top10-info {
    font-size: 1rem;
    color: white;
    width: 100%;
    margin: 1.5vh 0;
}

.top10-info p {
    margin-left: 4%;
    margin-bottom: 1%;
}

.top10-items {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.top10-error {
    margin: 5vw 10vw;
    text-align: center;
    color: rgba(255, 255, 255, .5);
}

.top10-error svg {
    font-size: 48px;
}

.top10-error h1 {
    font-size: 18px;
}

/* ITEM */
.top10-item {
    width: 92.5%;
    padding: .5vh 2vw;
    background: hsla(0,0%,100%,.25);
    border-radius: 5px;
    margin: 1vh;
}

/* HEADER */
.top10-item-header, .top10-item-footer {
    display: flex;
    justify-content: space-between;
    margin: 1% 0;
    font-size: .7rem;
    color: hsla(0,0%,100%,.65);
}

.top10-item-title {
    color: white;
    font-size: .75rem;
}

.top10-item-content {
    color: hsla(0,0%,100%,.65);
    font-size: .65rem;
}

.top10-item-footer a {
    color: hsla(0,0%,100%,.65);
}

@media screen and (min-width: 1240px) {

    .top10-logo-header {
        margin: 4vh 0;
        width: 8vw;
    }

    .top10-logo-header img {
        width: 100%;
    }

    .top10-info {
        font-size: 1.5rem;
        margin: 1vh 0;
    }

    .top10-items {
        justify-content: start;
        margin: 0 3.8vw;
    }

    .top10-item {
        width: 22.2vw;
        padding: .5vh .6vw;
        margin: .8vh;
    }

    .top10-item-header, .top10-item-footer, .top10-item-content {
        font-size: .8rem;
    }

    .top10-item-title {
        font-size: 1rem;
        margin-top: .8vh;
        margin-bottom: 2vh;
    }

}