.component-dropdown {
    opacity: 0;
    visibility: hidden;

    position: absolute;
    z-index: 1;
    transition: all .5s;
}

/* NOTIFICATION BUILD */
.component-dropdown-build {
    width: 0;
    height: 0;
    transform: translateY(2.1vw);

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid white;
}