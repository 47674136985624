.component-klipbox-noticia-skeleton-loading::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-image: linear-gradient(to right,
            rgba(255, 255, 255, .35),
            rgba(0, 0, 0, .10) 40%,
            rgba(255, 255, 255, .35));
    background-size: 450px 400px;
    background-repeat: no-repeat;
    animation: shimmer 2s linear infinite;
}

.component-klipbox-noticia-skeleton-body {
    background: rgba(255, 255, 255, .25);
    color: transparent;
    border-radius: 10px;

    display: inline-block;
    position: relative;
    overflow: hidden;
}

.component-klipbox-noticia-skeleton {
    margin: 0 1vw;
    color: white;

    min-width: 58vw;
    width: 58vw;
}

/* HEADER */
.component-klipbox-noticia-skeleton-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: .8%;

    color: rgba(255, 255, 255, .65);
    border-bottom: 1px solid white;
}

.component-klipbox-noticia-skeleton-header p {
    margin-bottom: 5px;
    height: 2vh;
}

/* TITLE */
.component-klipbox-noticia-skeleton-title h1 {
    font-size: 18px;
    padding-bottom: 7.5px;
    height: 3.75vh;
}

/* FILTROS */
.component-klipbox-noticia-skeleton-filtros {
    margin-bottom: 1%;
    min-width: 33.6%;
}

.component-klipbox-noticia-skeleton-filtros p {
    font-size: 16px;
    margin-bottom: .5%;
}

/* CONTENT */
.component-klipbox-noticia-skeleton-content {
    height: 40.8vh;
    font-size: 15px;
}

.component-klipbox-noticia-skeleton-content h1 {
    font-size: 20px;
    margin-bottom: .5%;
}

.component-klipbox-noticia-skeleton-content p {
    height: 2.5vh;
}

.component-klipbox-noticia-skeleton-filtros .component-klipbox-noticia-skeleton-filtro {
    width: 57.9vw;
    height: 35px;
    border-radius: 5px;
}

.component-klipbox-noticia-skeleton-filtro {
    width: 14.2vw;
    height: 28px;
    margin-right: 1vw;
    transition: all 1s;
}