.dashboard-sub-menus {
    display: inline-flex;
    align-items: center;

    margin: .5vw 1vw;

    background: rgb(34, 37, 46);
    border-radius: 10px;
}

/* SUB MENU ITEM */
.dashboard-sub-menu-item {
    float: left;
    display: inline-flex;
    color: white;

    padding: .4vw;
    margin: .35vh 1vw;
    cursor: pointer;

    transition: all .7s;
}

.dashboard-sub-menu-item:hover {
    background-color: rgba(255, 255, 255, .1);
    border-radius: 5px;
}

/* SUB MENU ITEM LINE */
.dashboard-sub-menu-item-line {
    border-bottom: 2px solid var(--cor-imarketing);
}

/* SUB MENU ITEM ICON */
.dashboard-sub-menu-item-icon {
    color: white;

    margin-top: .1vw;
    margin-right: .5vw;
}