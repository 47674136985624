.config-client-filtro {
    width: 100%;
    padding: 0 .8vw;
    margin-top: .8%;
    padding-top: 0;
    padding-bottom: .5vw;

    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}

.config-client-filtro-button {
    background-color: var(--cor-imarketing);
    border-radius: 5px;

    width: 85px;
    margin: 0;
    font-size: .9rem;
    padding: .24vw .8vw;

    transition: all .7s;
    text-align: center;
}

.config-client-filtro-button:last-child {
    margin-left: .7vw;
    background-color: rgba(255, 255, 255, .25);
}

.config-client-filtro-button:hover {
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(255, 255, 255, .5);
}

.component-filtro-item {
    margin: .5% 0;
}