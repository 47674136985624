/* LOADING */
.component-klipbox-noticia-skeleton-loading::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-image: linear-gradient(to right,
            rgba(255, 255, 255, .35),
            rgba(0, 0, 0, .10) 40%,
            rgba(255, 255, 255, .35));
    background-size: 450px 400px;
    background-repeat: no-repeat;
    animation: shimmer 2s linear infinite;
}

/* NOTICIA ITEM */
.component-klipbox-noticia-skeleton-item {
    height: 110px;
    margin: .4vw .6vw;

    background: rgba(255, 255, 255, .25);
    border-radius: .5em;
}

.component-klipbox-noticia-skeleton-item:first-child {
    margin-top: 0;
}

.component-klipbox-noticia-skeleton-item:last-child {
    margin-bottom: 0;
}

/* HEADER */
.component-klipbox-noticia-skeleton-item-header,
.component-klipbox-noticia-skeleton-item-footer {
    display: flex;
    justify-content: space-between;
}

.component-klipbox-noticia-skeleton-item-header span,
.component-klipbox-noticia-skeleton-item-footer span {
    font-size: .8rem;
    margin: .3vw .6vw;

    background: rgba(255, 255, 255, .25);
    color: transparent;
    border-radius: 20px;
    margin-right: 8px;

    display: inline-block;
    position: relative;
    overflow: hidden;
}

/* CONTENT */
.component-klipbox-noticia-skeleton-item-content {
    color: white;
    padding: .15vw .6vw;
    font-size: .9rem;
    height: 52px;
}

.component-klipbox-noticia-skeleton-item-content p {
    background: rgba(255, 255, 255, .25);
    color: transparent;
    border-radius: 20px;
    margin: 0 4px;
    height: 20px;

    display: inline-block;
    position: relative;
    overflow: hidden;
}

/* KEY FRAMES */
@keyframes shimmer {
    0% {
        background-position: -450px 0;
    }

    100% {
        background-position: 450px 0;
    }
}