.menu {
    background: rgb(34, 37, 46);

    height: 100%;
    width: 12vw;

    float: left;
    position: fixed;
    left: 0;
}

/* HEADER */
.menu-header {
    text-align: center;
    padding: 12% 4%;
}

/* MENU CONTENT */
.menu-content {
    padding: 4% 8%;
    height: 77.5%;

    overflow-y: scroll;
}

.menu-content::-webkit-scrollbar {
    width: 0px;
}

/* MENU FOOTER */
.menu-footer {
    padding: 10% 0;
    text-align: center;

    color: white;
}

.menu-footer p {
    line-height: .8rem;
    font-size: .65rem;
}

.menu-footer a {
    text-decoration: none;
    color: white;
}

.menu-footer span {
    font-size: .55rem;
}